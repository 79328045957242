.inputfield__label {
    display: flex;
    align-items: center;
    //font-family: $porsche-bold;
    font-weight: 600;
    font-size: remify(14px);
    line-height: var(--line-height);
    color: var(--color-neutral-30);
    transition: color 200ms ease-out;
}

.inputfield__label-optional {
    //font-family: $porsche;
    font-size: 12px;
    line-height: var(--line-height);
    color: var(--color-neutral-30);
    margin-left: auto;
}

.inputfield__input {
    padding: 15px 16px;
    font-size: remify(16px);
    font-weight: 400;
    line-height: var(--line-height);
    color: #000;
    border: 1px solid var(--color-neutral-30);
    border-radius: 4px;
    width: 100%;
    transition: border-color 200ms ease-out;

    &:hover,
    &:active {
        & ~ .inputfield__label {
            color: #000;
        }
    }

    &:focus {
        outline: none;

        & ~ .inputfield__input-focus {
            opacity: 1;
        }
    }

    &:disabled {
        cursor: not-allowed;
        border-color: var(--color-neutral-40);

        & ~ .inputfield__label {
            color: var(--color-neutral-40);
        }
    }

    &[type="password"] {
        color: var(--color-neutral-30);
    }

    .form__item--error & {
        border-color: var(--color-brand);
    }
}

.inputfield__input-focus {
    position: absolute;
    bottom: -4px;
    left: -4px;
    width: calc(100% + 8px);
    height: 64px;
    opacity: 0;
    pointer-events: none;
    outline: 1.5px solid var(--color-neutral-10);
    border-radius: 4px;
    transition: opacity 200ms ease-out;
}

.inputfield__input-date-holder {
    display: flex;
    align-items: center;
    position: absolute;
    left: 16px;
    bottom: 2px;
}

.inputfield__input-date {
    padding: 14px 0;
    border: none;
    outline: none;
    text-align: center;
}

.inputfield__input-date-day {
    width: 25px;
}

.inputfield__input-date-month {
    width: 30px;
}

.inputfield__input-date-year {
    width: 40px;
}

.inputfield__input-date-divider {
    padding: 0 2px 0 2px;
}

.inputfield__phone {
    position: absolute;
    bottom: 1px;
    right: 1px;
    width: calc(100% - 130px);
    padding-left: 0;
    border: none;
}

.inputfield__error {
    border-color: var(--color-brand) !important;
}
