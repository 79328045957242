@import "util.scss";
.soundButton {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.muted {
    .bar {
      animation: none;
    }
  }

  .bar {
    width: 1px;
    height: 25%;
    background-color: white;
    animation: height 750ms infinite linear;

    @include respond-to(MIN_1024) {
      width: 2px;
    }

    &:nth-child(1) {
      animation-delay: 75ms;
      animation-duration: 720ms;
    }
    &:nth-child(2) {
      animation-delay: 275ms;
      animation-duration: 800ms;
    }
    &:nth-child(3) {
      animation-delay: 175ms;
    }
  }
}

@keyframes height {
  0% {
    height: 25%;
  }
  50% {
    height: 100%;
  }
  0% {
    height: 25%;
  }
}