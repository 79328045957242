@import "util.scss";
.container {
  background: black;
  color: white;
  border: solid 1px white;
  padding: var(--space-32);
  padding-right: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-radius: var(--space-32);
  transition: transform .25s ease-out;
  overflow: hidden;

  @media (min-height: 900px) {
    padding: var(--space-32);
    padding-right: 0;
  }

  @include respond-to(MIN_1024) {
    max-height: 450px;
  }

  &:hover {
    transform: scale(1.01);
  }
}

a.container {
  text-decoration: none;
}

.textContainer {
  //padding-right: var(--space-80);
  padding-right: 10%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.title {
  @include heading-04();
  line-height: 112%; // Make it a big tighter

  &:last-of-type {
    margin-bottom: var(--space-16);
  }

  @media (min-height: 750px) {
    @include heading-03();
  }

  @media (min-height: 900px) {
    @include heading-02();
  }
}

.subtitle {
  @include body-md();
  margin-bottom: var(--space-16);

  @media (min-height: 900px) {
    @include body-lg();
  }
}

.red {
  color: var(--color-brand);
}

.rightVector {
  height: 50%;
  align-self: center;
  justify-self: end;
}

.button {
  margin-top: auto;
}

