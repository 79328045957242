.form__item-holder {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 24px;

    @include respond-to(MIN_1024) {
        margin-bottom: 32px;
    }

    &.form__item-holder--column {
        flex-direction: column;
    }
}

.form__error, .form__error-checkbox {
    position: absolute;
    bottom: -24px;
    display: flex;
    align-items: center;
    color: var(--color-brand);
    font-size: remify(12px);
    line-height: 2;

    &.--relative {
        position: relative;
        bottom: 0;
    }

    svg {
        display: block;
        width: 14px;
        height: 14px;
        margin-right: 10px;

        path {
            fill: var(--color-brand);
        }
    }
}

.form__error-checkbox {
    position: relative;
    bottom: 0;
}

.form__info {
    position: absolute;
    bottom: -24px;
    display: flex;
    align-items: center;
    color: var(--color-neutral-30);
    font-size: remify(12px);
    line-height: 2;

    svg {
        display: block;
        width: 14px;
        height: 14px;
        margin-right: 10px;

        path {
            fill: var(--color-neutral-30);
        }
    }
}

.form__note-text {
    font-size: remify(14px);
    line-height: 1.57;
    color: var(--color-neutral-30);
}

.form__note-link {
    color: var(--color-neutral-30) !important;
}
