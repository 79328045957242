.radio-button {
    &:not(:last-child) {
        margin-bottom: 16px;
    }

    &:hover {
        .radio-button__input {
            &:checked + label:before,
            &:not(:checked) + label:before {
                border-color: #fff;
            }

            &:checked + label:after,
            &:not(:checked) + label:after {
                background-color: #fff;
            }
        }

        &[data-theme="darktheme"] {
            .radio-button__input {
                &:checked + label:before,
                &:not(:checked) + label:before {
                    border-color: #000;
                }
    
                &:checked + label:after,
                &:not(:checked) + label:after {
                    background-color: #000;
                }
            }
        }
    }

    label {
        font-weight: 400;
    }

    &disabled,
    &[disabled] {
        .radio-button__input {
            cursor: not-allowed;

            &:checked + label:before,
            &:not(:checked) + label:before {
                border-color: var(--color-neutral-30);
                background-color: var(--color-neutral-20-dark);

                [data-theme="darktheme"] & {
                    border-color: var(--color-neutral-40-dark);
                    background-color: var(--color-neutral-40-dark);
                }
            }

            &:checked + label:after,
            &:not(:checked) + label:after {
                content: "";
                width: 1px;
                height: 21px;
                background-color: var(--color-neutral-30-dark);
                top: unset;
                bottom: 0;
                left: 0;
                opacity: 1;
                transform: rotate(45deg) translate(6px, -9px);

                [data-theme="darktheme"] & {
                    background-color: var(--color-neutral-40-dark);
                }
            }
        }

        .radio-button__label {
            cursor: not-allowed;
        }
    }
}

.radio-button__main-label {
    display: block;
    font-size: remify(14px);
    line-height: var(--line-height);
    color: var(--color-neutral-30);
    font-weight: 600;

    label {
        font-weight: 400;
    }
}

.radio-button__input {
    &:checked,
    &:not(:checked) {
        position: absolute;
        left: -9999px;
    }

    &:checked + label,
    &:not(:checked) + label {
        position: relative;
        padding-left: 40px;
        cursor: pointer;
        line-height: var(--line-height);
        display: inline-block;
        color: #fff;

        [data-theme="darktheme"] & {
            color: #000;
        }
    }

    &:checked + label:before,
    &:not(:checked) + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 22px;
        height: 22px;
        border: 1px solid var(--color-neutral-30);
        border-radius: 100%;
        background-color: #000;
        transition: border-color 200ms ease-out;

        [data-theme="darktheme"] & {
            border-color: var(--color-neutral-30-dark);
            background-color: #fff;
        }
    }

    &:checked + label:after,
    &:not(:checked) + label:after {
        content: '';
        width: 14px;
        height: 14px;
        background-color: var(--color-neutral-60-dark);
        position: absolute;
        top: 4px;
        left: 4px;
        border-radius: 100%;
        transition: transform 200ms ease-out, opacity 200ms ease-out, background-color 200ms ease-out;

        [data-theme="darktheme"] & {
            background-color: var(--color-neutral-30-dark);
        }
    }

    &:not(:checked) + label:after {
        opacity: 0;
        transform: scale(0);
    }

    &:checked + label:after {
        opacity: 1;
        transform: scale(1);
    }
}
