@import "util.scss";
.menu {
  position: fixed;
  top: var(--header-height);
  bottom: 0;
  overflow: auto;
  min-height: calc(100vh - var(--header-height));
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--color-black);
  z-index: zindex($zLayout, menu);
  visibility: hidden;

  // Hide scrollbars
  @include respond-to(MIN_1024) {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
      display: none;  /* Safari and Chrome */
      width: 0;
      height: 0;
    }
  }
}

.flexContainer {
  display: flex;
  justify-content: space-between;
  padding: var(--space-32) var(--grid-gutter);
  gap: var(--grid-gap);
  flex-direction: column;

  @include respond-to(MIN_768) {
    flex-direction: row;
    padding: var(--space-8) var(--grid-gutter);

    @media (min-height: 800px) {
      padding: var(--space-32) var(--grid-gutter);
    }
  }
}

.menuItemsContainer {
  display: flex;
  flex-direction: column;
  gap: var(--space-40);

  @include respond-to(MIN_1440) {
    gap: var(--space-60);
  }
}

.menuItem {
  font-size: var(--font-md);
  line-height: var(--line-height);

  @include respond-to(MIN_1024) {
    font-weight: var(--weight-semibold);
    font-size: remify(50px);
  }

  @include respond-to(MIN_1440) {
    font-size: remify(70px);
  }

  @include respond-to(MIN_1920) {
    font-size: var(--font-xxl);
  }

  a {
    transition: filter .25s;
    &:hover {
      filter: drop-shadow(1px 1px 4px var(--color-brand));
    }
  }


}

.newsletterContainer {
  max-width: 490px;
  @include respond-to(MIN_1024) {
    max-width: 50%;
  }
}