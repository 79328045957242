.checkbox {
    position: relative;
    display: inline-block;
    margin-bottom: 16px;
    margin-right: 16px;

    &:hover {
        .checkbox__label {
            &::before {
                border-color: #fff;
            }
        }

        &[data-theme="darktheme"] {
            .checkbox__label {
                &::before {
                    border-color: #000;
                }
            }
        }
    }

    &disabled,
    &[disabled] {
        cursor: not-allowed;

        .checkbox__input,
        .checkbox__label,
        .checkbox__label:before,
        .checkbox__label:after {
            color: var(--color-neutral-30);
            cursor: not-allowed;

            [data-theme="darktheme"] & {
                color: #000;
            }
        }

        // .checkbox__input:checked + .checkbox__label,
        .checkbox__input+.checkbox__label {
            &::after {
                content: '';
                display: block;
                position: absolute;
                top: unset;
                bottom: 10px;
                left: -3px;
                width: 26px;
                height: 1px;
                background-color: var(--color-neutral-30);
                border: none;
                transform: rotate(-45deg);
            }
        }

        .checkbox__input:checked+.checkbox__label::before {
            border-color: var(--color-neutral-30);
        }

        .checkbox__input:checked+.checkbox__label:after {
            content: '';
            display: block;
            position: absolute;
            top: 5px;
            bottom: unset;
            left: 7px;
            width: 6px;
            height: 14px;
            border: solid var(--color-neutral-30);
            background-color: transparent;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg) scale(1);
            transform-origin: center center;
            transition: transform 200ms ease-out;
        }

        &:hover {
            .checkbox__label {
                &::before {
                    border-color: var(--color-neutral-30);
                }
            }
        }
    }
}

.checkbox__input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.checkbox__label {
    display: block;
    position: relative;
    color: #fff;
    padding-left: 36px;
    cursor: pointer;

    &::before {
        content: '';
        background-color: transparent;
        border: 1px solid var(--color-neutral-30);
        padding: 9px;
        display: inline-block;
        position: absolute;
        left: 0;
        top: 3px;
        vertical-align: middle;
        cursor: pointer;
        margin-right: 8px;
        transition: border-color 200ms ease-out;
    }

    [data-theme="darktheme"] & {
        color: #000;
    }
}

.checkbox__input:checked+.checkbox__label:before {
    border-color: #fff;

    [data-theme="darktheme"] & {
        border-color: #000;
    }
}

.checkbox__input+.checkbox__label:after {
    content: '';
    display: block;
    position: absolute;
    top: 5px;
    left: 7px;
    width: 6px;
    height: 14px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg) scale(0);
    transform-origin: center center;
    transition: transform 200ms ease-out;

    [data-theme="darktheme"] & {
        border-color: #000;
    }
}

.checkbox__input:checked+.checkbox__label:after {
    transform: rotate(45deg) scale(1);
}
