@import "util.scss";
.footer {
  padding: var(--space-32) var(--grid-gutter);

  --column-width: #{column-width(4)};
  @include respond-to(MIN_1440) {
    --column-width: #{column-width(3)};
  }

  a {
    transition: color .25s;
    &:hover {
      color: var(--color-brand);
    }
  }
}

.sectionsContainer {
  @include respond-to(MIN_1024) {
    display: flex;
    flex-wrap: wrap;
    column-gap: var(--grid-gap);

    hr {
      display: none;
    }
  }
}

.section {
  margin-bottom: var(--space-32);

  @include respond-to(MIN_1024) {
    width: var(--column-width);
  }

  li {
    margin-bottom: 16px;

    a {
      display: block;
      width: 100%;
    }
  }
}

.flexLine {
  display: flex;
}

.gridGap24 {
  grid-gap: var(--space-24);
}

.legal {
  display: flex;
  flex-direction: column;
  row-gap: var(--space-16);
  @include respond-to(MIN_1024) {
    min-width: column-width(6);
    flex-direction: row;
    align-items: center;
    column-gap: var(--grid-gap);

    p {
      min-width: var(--column-width);
    }
  }
}

.legalLinksContainer {
  display: flex;
  gap: var(--space-16);

  @include respond-to(MIN_1024) {
    gap: var(--space-40);
  }
}

.legalLink {
  text-decoration: underline;
  color: var(--color-neutral-50);
}

.social {
  margin-bottom: var(--space-48);

  ul {
    margin-top: var(--space-16)
  }

  @include respond-to(MIN_1024) {
    margin-bottom: 0;
    order: 5;
    margin-left: auto;
  }
}

.socialIcon {
  svg {
    width: 24px;
    height: 24px;

    path {
      transition: color .25s
    }
  }

  &:hover {
    svg {
      path {
        fill: var(--color-brand);
      }
    }

    .facebook {
      path {
        stroke: var(--color-brand)
      }
    }
  }
}

.clickableDiv {
    transition: color .25s;
  &:hover {
    color: var(--color-brand);
  }
}