@import "util.scss";
@import '../../organisms/contact-form/input';

.phone-input-icon {
    position: absolute;
    bottom: 28px;
    right: 24px;
    transform: translateY(50%);
    pointer-events: none;
    z-index: 1;

    &.phone-input-icon--phone {
        right: unset;
        left: 48px;
    }
}

.phone-input-container {
    display: flex;
    flex-direction: column;
}

.phone-input__phone-holder {
    padding: 15px 16px 15px 46px;
}

.phone-input-holder {
    width: 100%;
    padding: 15px 16px;
    font-size: remify(16px);
    line-height: var(--line-height);
    color: #000;
    border: 1px solid var(--color-neutral-30);
    border-radius: 4px;
    transition: border-color 200ms ease-out;

    &:hover,
    &:active {
        &~.inputfield__label {
            color: #000;
        }
    }

    &:focus {
        outline: none;

        &~.inputfield__input-focus {
            opacity: 1;
        }
    }

    &:disabled {
        cursor: not-allowed;
        border-color: var(--color-neutral-40);

        &~.inputfield__label {
            color: var(--color-neutral-40);
        }
    }
}

.phone-input-selected {
    display: flex;
    align-items: center;
    height: 24px;
    pointer-events: none;

    img {
        display: block;
        height: 14px;
        width: 20px;
        margin-right: 18px;

        .phone-input__phone-container & {
            margin-right: 42px;
        }
    }
}

.phone-input__mobile-holder {
    position: relative;

    &:after {
        content: 'text';
        position: absolute;
        right: 0;
        top: 0;
    }

    @include respond-to(MIN_1024) {
        display: none;
    }
}

.phone-input__desktop-holder {
    display: none;

    @include respond-to(MIN_1024) {
        display: block;
        position: relative;
        overflow: hidden;
        cursor: pointer;
    }
}

.phone-input__default-holder {
    position: relative;
    background-color: #fff;
    overflow: hidden;
}

.phone-input__phone-container {
    position: absolute;
    bottom: 1px;
    left: 0;
    width: 100%;
    border: none;
    overflow: hidden;
}

.phone-input__list {
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 0;
    padding: 0;
    overflow: hidden;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 1px solid var(--color-neutral-30);
    border-top: none;
    max-height: 310px;
    overflow-y: scroll;
    z-index: 2;
}

.phone-input__list--default {
    display: none;

    @include respond-to(MIN_1024) {
        display: block;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 0;
        padding: 0;
        overflow: hidden;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        border: 1px solid var(--color-neutral-30);
        border-top: none;
        max-height: 300px;
        overflow-y: scroll;
        z-index: 2;
    }
}

.phone-input__item-holder {
    display: flex;
    padding: 16px;
    width: 100%;
    background-color: #fff;
    transition: background-color 200ms ease-out;
    cursor: pointer;

    &:hover,
    &:active {
        background-color: var(--color-neutral-60);
    }
}

.phone-input__item-image-holder {
    width: 122px;
    height: 67px;
}

.phone-input__item-description-holder {
    width: 105px;
}

.phone-input__item-title {}

.phone-input__item-flag-holder {
    margin-right: 18px;

    img {
        display: block;
        height: 14px;
        width: 20px;
    }
}

.phone-input__item-description {}

.phone-input__item-cta-holder {
    display: flex;
}

.phone-input__item-cta {
    color: #000;
}

.phone-input__label {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: remify(14px);
    line-height: var(--line-height);
    color: var(--color-neutral-30);
    transition: color 200ms ease-out;
}

.inputContainer {
    position: relative;
}

.flag {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: remify(14px);
    width: remify(50px);
}
