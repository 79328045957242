.textarea__label {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: remify(14px);
    line-height: 1.43;
    color: var(--color-neutral-30);
    grid-area: 1 / 1 / 2 / 2;
    transition: color 200ms ease-out;
}

.textarea-holder {
    /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
    display: grid;
}

.textarea-holder::after {
    /* Note the weird space! Needed to preventy jumpy behavior */
    content: attr(data-replicated-value) " ";
  
    /* This is how textarea text behaves */
    white-space: pre-wrap;
  
    /* Hidden from view, clicks, and screen readers */
    visibility: hidden;
}

.textarea-holder > .textarea-field {
    /* You could leave this, but after a user resizes, then it ruins the auto sizing */
    resize: none;
  
    /* Firefox shows scrollbar on growth, you can hide like this. */
    overflow: hidden;
}

.textarea-holder > .textarea-field,
.textarea-holder::after {
    padding: 15px 16px;
    font-size: remify(16px);
    line-height: 1.5;
    color: #000;
    border: 1px solid var(--color-neutral-50);
    overflow-wrap: break-word;
    border-radius: 4px;
    outline: none;
    transition: border-color 200ms ease-out;
  
    /* Place on top of each other */
    grid-area: 2 / 1 / 3 / 2;

    &::placeholder {
        color: var(--color-neutral-50);
    }
}

.textarea-field {
    &:active,
    &:focus {
        border-color: var(--color-neutral-20);

        & ~ .textarea__label {
            color: #000
        }
    }

    &:disabled {
        cursor: not-allowed;
        border-color: var(--color-neutral-40);

        & ~ .textarea__label {
            color: var(--color-neutral-40);
        }
    }
}

.textarea__character-count {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    color: var(--color-neutral-30);
    font-size: remify(12px);
    line-height: 2;
}

.textarea__note-text {
    font-size: remify(12px);
    line-height: 2;
    color: var(--color-neutral-30);
}
