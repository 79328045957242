@import "util.scss";
/* stylelint-disable-next-line block-no-empty */
.accordion {

}

.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--space-20);
}

.content {
  overflow: hidden;
}

