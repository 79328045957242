@import "util.scss";
.header {
  position: fixed;
  z-index: zindex($zLayout, header);
  width: 100%;
  margin: 0 auto;
  padding: var(--space-28) var(--grid-gutter);
  height: var(--header-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: black;
  font-weight: 400;

  @include respond-to(MIN_1024) {
    padding: var(--space-32) var(--grid-gutter);
  }
}

.leftPart {
  position: relative;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.rightPart {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 40px;
}

.buttonLeft {
  position: absolute;
  right: -40px;
  transform: translateX(100%);
  width: 100px;
}

.buttonBottom {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(100%);
  background: black;
  width: 100%;
  padding: 8px;
  text-align: center;
}