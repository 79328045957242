@import "util.scss";
@import 'button';
@import 'checkbox';
@import 'form';
@import 'input';
@import 'radio-button';
@import 'textarea';

.modelBackdrop {
  position: fixed;
  top: 0;
  right: 0;
  width: 200vw;
  height: 100vh;
  opacity: 0;
  //min-height: -webkit-fill-available;
  //min-height: stretch;
  //min-height: fit-content;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 24;
}

.modelHolder {
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  //min-height: -webkit-fill-available;
  //min-height: stretch;
  //min-height: fit-content;
  font-weight: 400;
  color: #000;
  background-color: #fff;
  z-index: 25;

  @include respond-to(MIN_1024) {
    width: 100%;
    max-width: 624px;
  }

  ::selection {
    background: #000!important;
    color: #fff!important;
  }

  ::-moz-selection {
    background: #000!important;
    color: #fff!important;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}

.modelHeaderHolder {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: auto;
  background-color: #fff;
  z-index: 1;

  @include respond-to(MIN_1024) {
    max-width: 624px;
  }
}

.modelHeaderControlsHolder {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px 0;
  margin: 0 22px;

  @include respond-to(MIN_1024) {
    padding: 22px 0;
    margin: 0 45px;
  }

  &.modelHeaderControlsHolderLarge {
    padding: 24px 0;
    margin: 0 45px;
  }
}

.modelHeaderControlsBack {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  cursor: pointer;
}

.modelHeaderControlsTextHolder {
  display: flex;
  flex-direction: row;
  align-items: center;
}


.modelHeaderControlsPageIndicator {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  background-color: var(--color-neutral-50);
  border-radius: 50%;

  @include respond-to(MIN_768) {
    width: 32px;
    height: 32px;
  }
}

.modelHeaderControlsPageIndicatorCurrent {
  position: absolute;
  top: 2px;
  left: 5px;
  font-size: remify(10px);

  @include respond-to(MIN_768) {
    top: 5px;
    left: 8px;
  }
}

.modelHeaderControlsPageIndicatorSeparator {
  font-size: remify(19px);
}

.modelHeaderControlsPageIndicatorMax {
  position: absolute;
  bottom: 2px;
  left: 12px;
  font-size: remify(10px);

  @include respond-to(MIN_768) {
    bottom: 5px;
    left: 18px;
  }
}

.modelHeaderCloseHolder {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  cursor: pointer;
}

.modelHeaderIndicator {
  position: relative;
  height: 4px;
  width: 100%;
  background-color: var(--color-neutral-50);
  overflow: hidden;
}

.modelHeaderIndicatorCurrent {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: var(--color-brand);
  transform: translateX(-100%);
  transition: transform 550ms ease-out;
}

.container {
  margin-top: 60px;
  padding: 16px 16px 54px 16px;
  height: calc(100vh - 60px);
  overflow-y: scroll;

  @include respond-to(MIN_768) {
    padding: 36px 24px 80px 24px;
    margin-top: 80px;
    height: calc(100vh - 80px);
  }

  @include respond-to(MIN_1024) {
    padding: 36px 36px 80px 36px;
  }
}



.modelTextHolder {
  p {
    font-weight: 400;
  }

  margin-bottom: 16px;

  @include respond-to(MIN_1024) {
    margin-bottom: 32px;
  }
}


.modelCtaHolder {
  position: relative;
  // margin-top: 16px;

  // @include respond-to(MIN_768 {
  //     margin-top: 24px;
  // }

  // @include respond-to(MIN_1024 {
  //     margin-top: 32px;
  // }
}

.modelContentErrorHolder {
  display: flex;
  flex-direction: row;
  padding: 16px;
  border: 1px solid var(--color-brand);
  border-radius: 4px;
}

.modelContentErrorTitle {
  font-size: remify(16px);
  line-height: 1.5;
  margin-bottom: 8px;
  color: var(--color-brand);
}

.modelContentErrorFieldsNotice {
  font-size: remify(12px);
  line-height: 2;
  color: #000;
  margin-bottom: 8px;
}

.modelContentErrorFields {
  font-size: remify(12px);
  line-height: 2;
  padding-left: 20px;
  list-style: disc;
  color: var(--color-brand);

  li {
    text-decoration: underline;
  }
}

.modelContentErrorClose {
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
  }
}

.subheading2 {
  @include body-lg();
  font-weight: 600;

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
}

.subheading3 {
  font-size: 1.125rem;
  line-height: var(--line-height);
  font-weight: 400;

  @media(min-width: 1024px) {
    font-size:1.5rem;
    line-height: var(--line-height);
  }
}


.subheading5 {
  font-size: .875rem;
  font-weight: 400;
}

.grid {
  display: grid;
  grid-template-columns: repeat(12,[col-start] 1fr);
  gap: 8px;
  row-gap: 0;
  width: 100%;
  margin: 0 auto;

  @include respond-to(MIN_768) {
    grid-gap: 16px;
    gap: 16px;
    grid-row-gap: 0;
    row-gap: 0;
    grid-template-columns: repeat(12,[col-start] 1fr);
  }

  @include respond-to(MIN_768) {
    max-width: 1344px;
    grid-gap: 24px;
    gap: 24px;
    grid-row-gap: 0;
    row-gap: 0;
  }
}

.buttonIcon {
  margin-left: var(--space-8);
  height: remify(24px);
  width: remify(24px);

  path {
    //stroke: #fff;
    //fill: #000!important;
  }
}

.blackIcon {
  path {
    stroke: #000
  }
}

.icon32 {
  height: remify(32px);
  width: remify(32px);
}

.crossIcon {
  path {
    stroke-width: 2.5px;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
}

.gridCol6 {
  grid-column: span 6;
}

.gridCol12, .grid-col-12 {
  grid-column: span 12;
}

.gridColMb12 {
  @include respond-to(MAX_767) {
    grid-column: span 12;
  }
}

.gridColTp6 {
  @include respond-to(MIN_768) {
    grid-column: span 6;
  }
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.image-holder-16-9 {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 0;
  padding-bottom: 63.93%; // 429/671
}

.ml8, .ml-8 {
  margin-left: var(--space-8);
}

.mb-8 {
  margin-bottom: var(--space-8);
}

.mb-16 {
  margin-bottom: var(--space-16);
}

.mb-32 {
  margin-bottom: var(--space-32);
}

.mb-48 {
  margin-bottom: var(--space-48);
}

.mr-16 {
  margin-right: var(--space-16);
}

.mt-40 {
  margin-top: var(--space-40);
}
